<template>
  <div class="password-box">
    <!-- password -->
    <b-form-group
      label-for="register-password"
      label="Password"
    >
      <validation-provider
        #default="{ errors }"
        name="password"
        vid="password"
        rules="required|password:6|reg"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="register-password"
            ref="password"
            :value="password"
            class="form-control-merge"
            :type="passwordFieldType"
            :state="errors.length > 0 ? false:null"
            name="register-password"
            placeholder="Password"
            autocomplete="new-password"
            @input="(val) => $emit('update:password', val)"
          />
          <b-input-group-append is-text>
            <feather-icon
              :icon="passwordToggleIcon"
              class="cursor-pointer"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- confirm password -->
    <b-form-group
      label-for="register-confirm-password"
      label="Confirm Password"
    >
      <validation-provider
        #default="{ errors }"
        name="confirm password"
        rules="required|password:6|reg|confirmed:password"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="register-confirm-password"
            :value="confirmPassword"
            class="form-control-merge"
            :type="confirmPasswordFieldType"
            :state="errors.length > 0 ? false:null"
            name="register-confirm-password"
            placeholder="Confirm Password"
            autocomplete="new-password"
            @input="(val) => $emit('update:confirmPassword', val)"
          />
          <b-input-group-append is-text>
            <feather-icon
              :icon="confirmPasswordToggleIcon"
              class="cursor-pointer"
              @click="toggleConfirmPasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { extend, ValidationProvider } from 'vee-validate'
import { required, confirmed } from '@validations'
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  props: {
    password: {
      type: String,
      default: '',
    },
    confirmPassword: {
      type: String,
      default: '',
    },
  },
  data() {
    extend('password', {
      validate(value, { min }) {
        return value.length >= min
      },
      params: ['min'],
      message:
        'The password must be a combination of at least 6 numbers and letters.',
    })
    extend('reg', {
      validate(value) {
        // return /^[a-zA-Z0-9]\w{5,32}$/.test(value)
        const regExpPassword = /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,}/
        return regExpPassword.test(value)
      },
      message: '{_field_} must be at least 6 characters and include a number, a letter and a symbol ',
    })
    extend('confirmed', {
      ...confirmed,
      message: 'The two passwords entered are inconsistent',
    })
    // 手机号验证：小于11位且不等于0
    extend('required', {
      ...required,
      message: 'Please enter your {_field_}.',
    })
    return {
      required,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {

  },
}
</script>
<style lang="scss">
</style>
