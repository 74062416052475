<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.basicInfo.company_email || "" }}
          </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
      <b-dropdown-item
        v-if="isProcess"
        link-class="d-flex align-items-center"
      >
        <b-link :to="{name:'Profile'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-link>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isProcess"
        style="width: 14rem;"
        link-class="d-flex align-items-center"
        @click="modify"
      >
        <feather-icon
          size="16"
          icon="RepeatIcon"
          class="mr-50"
        />
        <span>Change Password</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <!-- modal -->
    <b-modal
      v-model="modalShow"
      title="Change Password"
      ok-title="Confirm"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="passwordRules">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="handleSubmit"
        >
          <!-- originalPassword -->
          <b-form-group
            label-for="original-password"
            label="Old Password"
          >
            <validation-provider
              #default="{ errors }"
              name="old password"
              vid="original-password"
              rules="required|password:5|reg"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="original-password"
                  v-model="originalPassword"
                  class="form-control-merge"
                  :type="originalPasswordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="original-password"
                  placeholder="Old Password"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="originalPasswordToggleIcon"
                    class="cursor-pointer"
                    @click="toggleOriginalPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <password-component
            ref="passwordComponent"
            :password.sync="passwordValue"
            :confirm-password.sync="confirmPasswordValue"
          />
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BLink,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import storage from '@/libs/storage'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { aseEncrypt, showToast } from '@/libs/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import PasswordComponent from '@/views/pages/authentication/components/PasswordComponent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    PasswordComponent,

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    extend('password', {
      validate(value, { min }) {
        return value.length >= min
      },
      params: ['min'],
      message:
        'The password must be a combination of at least 5 numbers and letters.',
    })
    extend('reg', {
      validate(value) {
        return /^[a-zA-Z0-9]\w{5,32}$/.test(value)
      },
      message: 'The {_field_} is not valid',
    })
    // 手机号验证：小于11位且不等于0
    extend('required', {
      ...required,
      message: 'Please enter your {_field_}.',
    })
    return {
      userData: storage.getItem('userData') || {},
      avatarText,
      required,
      modalShow: false,
      originalPassword: '',
      passwordValue: '',
      confirmPasswordValue: '',
    }
  },
  computed: {
    originalPasswordToggleIcon() {
      return this.originalPasswordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    isProcess() {
      const userData = storage.getItem('userData')
      if (userData && userData.kycStatus === 3) {
        return false
      }
      return true
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      storage.clearItem(useJwt.jwtConfig.storageTokenKeyName)
      storage.clearItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      storage.clearItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // clear all
      storage.clearAll()

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      window.location.href = `${window.location.origin}/login`
    },
    modify() {
      this.modalShow = true
    },
    resetModal() {
      this.originalPassword = ''
      this.passwordValue = ''
      this.confirmPasswordValue = ''
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      const that = this
      this.$refs.passwordRules.validate().then(success => {
        if (success) {
          that.resetPassword()
        }
      })
    },
    resetPassword() {
      const params = {
        platform: 2,
        password: aseEncrypt(this.originalPassword),
        new_password: aseEncrypt(this.passwordValue),
        confirm_password: aseEncrypt(this.confirmPasswordValue),
      }
      this.$showLoading()
      this.$api.resetPassword(params)
        .catch(error => {
          this.$hideLoading()
          showToast({ proxy: this, title: 'danger', message: error.message })
        })
        .then(res => {
          this.$hideLoading()
          const { code = 0, message = 'error' } = res.data || {}
          if (code === 200) {
            showToast({ proxy: this, title: 'Success', message: 'Reset Success' })
            storage.clearAll()
            setTimeout(() => { this.$router.push({ name: 'auth-login' }) }, 2000)
          } else {
            showToast({ proxy: this, title: 'danger', message })
          }
        })
    },
  },
}
</script>
